
.toastStyle {
	border: 1px solid #666;
	border-radius: 4px;
	padding: 10px;
	color: #fff;
	font-size: 1.6em;
	font-weight: 500;
	text-transform: uppercase;
	text-align: center;
}

.toastWrapper {
	position: fixed;
	top: 5px;
	/* left: 5%; */
	width: 100%;
	z-index: 2000 !important;
}

.floating {
	background-color: blue;
	filter: drop-shadow(2px 2px 2px #666);
	max-width: 700px;
	margin: 0 auto;
}

.success {
	background-color: #5cb85c;
}

.failure {
	background-color: #d9534f;
}

.info {
	background-color: #5bc0de;
}
