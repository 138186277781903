/* new color theme */
.btn-primary, .atn-btn-primary {
	color: #fff;
	background-color: #0F1F41;
	border-color: #0F1F41;
}
.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle,
.btn-primary.focus, .btn-primary:focus, .btn-primary:hover {
	color: #fff;
	background-color: #21335B;
	border-color: #21335B;
}
.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: #0F1F41;
    border-color: #0F1F41;
}

.dropdown-item.active, .dropdown-item:active {
    color: #fff;
    text-decoration: none;
    background-color: #E4AF66;
}

.isomorphicSidebar {
	background: #0e203f !important;
}
.hiPzsd .isomorphicSidebar .isoDashboardMenu .ant-menu-item-selected {
	background-color: #c1893d !important;
}